import React from "react";

import ServicePress from "../composant-generique/ServicePress";

function All(props) {
  return <ServicePress categorie="all"></ServicePress>;
}

export default All;



