export class Post {
    constructor(date, src,description, width, height, titre, images, height2,width2 , height3,width3 , height4,width4, urlPremiereVideo ,urlSecondeVideo,  lienExterieur,lieninsta ) {
      this.date = date;
      this.src = src;
      this.description = description;
      this.width = width;
      this.height = height;
      this.titre = titre;
      this.images = images;
      this.width2 = width2;
      this.height2 = height2;
      this.width3 = width3;
      this.height3 = height3;
      this.width4 = width4;
      this.height4 = height4;

      this.urlPremiereVideo = urlPremiereVideo;
      this.urlSecondeVideo = urlSecondeVideo;
      this.lienExterieur = lienExterieur;
      this.lieninsta = lieninsta;
   
    }
  }