import React from "react";

import ServicePress from "../composant-generique/ServicePress";

function Meeting(props) {
  return <ServicePress categorie="meeting"></ServicePress>;
}

export default Meeting;



