

import React from "react";

import ServiceProject from "../composant-generique/ServiceProject";

function Paintings(props) {
  return <ServiceProject categorie="paintings"></ServiceProject>;
}

export default Paintings;

