import React from "react";

import ServicePress from "../composant-generique/ServicePress";

function Newspapers(props) {
  return <ServicePress categorie="newspaper"></ServicePress>;
}

export default Newspapers;





