

import React from "react";

import ServiceProject from "../composant-generique/ServiceProject";

function Design(props) {
  return <ServiceProject categorie="design"></ServiceProject>;
}

export default Design;



