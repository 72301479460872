
import React from "react";

import ServiceProject from "../composant-generique/ServiceProject";

function Clip(props) {
  return <ServiceProject categorie="clip"></ServiceProject>;
}

export default Clip;


